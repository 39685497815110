export default [
  {
    title: 'Home',
    action: "read",
    route: "app-root",
    icon: 'HomeIcon',
    resource: "AppRoot"
  },

  {
    title: 'Rentals',
    action: "read",
    route: "rentals",
    icon: 'SunriseIcon',
    resource: "AppRoot"
  },

  {
    title: 'About Us',
    action: "read",
    route: "about-us",
    icon: 'SunriseIcon',
    resource: "AppRoot"
  },
]
