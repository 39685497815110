export default [
  {
    title: 'Dashboard',
    icon: 'GridIcon',
    tagVariant: 'light-warning',
    route: "admin-home",
    action: "read",
  },
  {
    header: 'Admins & Customers',
    permissions: [
      "admin:CanList",
      "users:CanList"
    ]
  },
  {
    title: 'Administrators',
    icon: 'UserPlusIcon',
    tagVariant: 'light-warning',
    route: "admin",
    action: "read",
    permissions: ["admin:CanList"],
    childrenNestedRoutes: [
      'admin-new-user'
    ]
  },
  {
    title: 'Customers',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    route: "admin-customers",
    action: "read",
    permissions: ["admin:CanList"],
  },
  {
    header: 'Bookings & Cars',
    permissions: [
      "cars:CanList",
      "car_types:CanList",
      "car_brands:CanList",
      "car_images:CanList",
      "car_features:CanList",
      "car_bookings:CanList",
    ]
  },
  {
    title: 'Cars',
    icon: 'TruckIcon',
    tagVariant: 'light-warning',
    route: "admin-cars",
    action: "read",
    permissions: ["cars:CanList"],
  },
  {
    title: 'Bookings',
    icon: 'PaperclipIcon',
    tagVariant: 'light-warning',
    route: "admin-car-bookings",
    action: "read",
    permissions: ["car_bookings:CanList"],
  },
  {
    title: 'Refunds',
    icon: 'CreditCardIcon',
    tagVariant: 'light-warning',
    route: "admin-refund-requests",
    action: "read",
    permissions: ["refund_requests:CanList"],
  },
  {
    title: 'Locations',
    icon: 'MapPinIcon',
    tagVariant: 'light-warning',
    route: "admin-booking-locations",
    action: "read",
    permissions: ["booking_locations:CanList"],
  },
  {
    title: 'Drivers',
    icon: 'UserIcon',
    tagVariant: 'light-warning',
    route: "admin-drivers",
    action: "read",
    permissions: ["drivers:CanList"],
  },
  {
    header: 'App Settings'
  },
  {
    title: 'Car Brands & More',
    icon: 'SlidersIcon',
    tagVariant: 'light-warning',
    route: "admin-car-details",
    action: "read",
    permissions: [
      "car_types:CanList",
      "car_brands:CanList",
      "car_images:CanList",
      "car_features:CanList",
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'General',
        icon: 'SettingsIcon',
        tagVariant: 'light-warning',
        route: "admin-settings",
        action: "read",
        permissions: ["settings:CanList"],
      },
      {
        title: 'Sample CSV Files',
        icon: 'FileIcon',
        tagVariant: 'light-warning',
        route: "admin-sample-csv-files",
        action: "read",
        permissions: ["sample_csv_files:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-new-permission',
      'admin-update-permission',
      'admin-new-sample-csv-file',
      'admin-update-sample-csv-file'
    ]
  },
  {
    title: 'Reports',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: 'Sales Report',
        icon: 'TrendingUpIcon',
        route: "car-bookings-reports",
        action: "read",
        permissions: ["car_bookings:CanList"],
      },
      {
        title: 'Drivers Report',
        icon: 'UserIcon',
        route: "driver-reports",
        action: "read",
        permissions: ["drivers:CanList"],
      },
    ],
  },
  {
    title: 'Administration',
    icon: 'MinusCircleIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Resources',
        icon: 'BoxIcon',
        tagVariant: 'light-warning',
        route: "admin-resources",
        action: "read",
        permissions: ["resource:CanList"],
      },
      {
        title: 'Policies',
        icon: 'LockIcon',
        tagVariant: 'light-warning',
        route: "admin-policies",
        action: "read",
        permissions: ["policy:CanList"],
      },
      {
        title: 'Roles',
        icon: 'LockIcon',
        tagVariant: 'light-warning',
        route: "admin-user-roles",
        action: "read",
        permissions: ["user_role:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-resources',
      'admin-new-resources',
      'admin-update-resource',
      'admin-resource-actions',
      'admin-new-resource-action',
      'admin-update-resource-action',
      'admin-policies',
      'admin-new-policy',
      'admin-user-roles',
      'admin-view-policies',
      'admin-new-user-role',
      'admin-update-user-role',
    ]
  },
  {
    header: 'Others',
    permissions: [
      "faqs:CanList",
      "blogs:CanList",
      "admin_logs:CanList",
      "reported_problems:CanList"
    ]
  },
  // {
  //   title: 'Miscellaneous',
  //   icon: 'CpuIcon',
  //   tagVariant: 'light-warning',
  //   // children: [
  //   //   {
  //   //     title: 'Blogs',
  //   //     action: "read",
  //   //     route: "admin-blogs",
  //   //     icon: 'ClipboardIcon',
  //   //     tagVariant: 'light-warning',
  //   //     permissions: ["blogs:CanList"],
  //   //   }
  //   // ],
  //   childrenNestedRoutes: [
  //     'admin-new-faq',
  //     'admin-new-blog',
  //     'admin-single-faq',
  //     'admin-update-faq',
  //     'admin-single-blog',
  //     'admin-update-blog',
  //   ]
  // },
  {
    title: 'Contact Emails',
    icon: 'MailIcon',
    tagVariant: 'light-warning',
    route: "admin-contact-emails",
    action: "read",
    permissions: ["contact_emails:CanList"],
  },
  {
    title: 'Reported Problems',
    icon: 'InfoIcon',
    tagVariant: 'light-warning',
    route: "admin-reported-problems",
    action: "read",
    permissions: ["reported_problems:CanList"],
  },
  {
    title: 'Logs',
    icon: 'ListIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Admin Logs',
        action: "read",
        icon: 'ListIcon',
        route: "admin-logs",
        tagVariant: 'light-warning',
        permissions: ["admin_logs:CanList"]
      },
      {
        title: 'User Logs',
        action: "read",
        icon: 'ListIcon',
        route: "user-logs",
        tagVariant: 'light-warning',
        permissions: ["logs:CanList"]
      },
      {
        title: 'Audit Trail',
        action: "read",
        route: "audit-trail",
        icon: 'GitBranchIcon',
        tagVariant: 'light-warning',
        permissions: ["audit_trail:CanList"]
      },
    ],
    childrenNestedRoutes: [
      'admin-logs',
      'user-logs',
      'audit-trail'
    ]
  },
]
