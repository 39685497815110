<template>
  <div
    class="content"
    :class="[
    {
      'show-overlay': $store.state.app.shallShowOverlay 
    }, 
    {
      'app-content': true
    },
    $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="{
        'container': contentWidth === 'boxed',
        'p-0': contentWidth === 'boxed',
        'override-content-wrapper': false,
        'explicit-height': $route.meta.useContentWrapper
      }"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    AppBreadcrumb,
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    isAdminOrVendor(){
      return ['vendor', 'admin'].includes(this.getValueFromSource(this.userData, 'user_type'))
    },
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth
    }
  },
}
</script>
<!-- 
<style>
.content-wrapper.override-content-wrapper {
  /* margin-top: 50px !important; */
}
.override-padding {
  padding: calc(2rem + 11rem + 4.45rem + 1.3rem) 2rem 0 !important;
  /* padding-bottom: 0px; */
}
</style> -->
